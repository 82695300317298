import * as React from "react"
import * as Styles from "../components/Home.module.scss"
import Favicon from "../components/media/favicon.png"
import Logo from "../components/media/logo.svg"

// markup
const IndexPage = () => {
  return (
    <div className={Styles.container}>
      <title>Fourpoint Creative</title>

      <link rel="icon" href={Favicon} />

      <main className={Styles.main}>
        <embed className={Styles.logo} src={Logo} alt="4pt" />
        <h1 className={Styles.title}>
          fourpoint
        </h1>

        <p className={Styles.description}>
          bbiab :3
        </p>
      </main>
    </div>
  )
}

export default IndexPage
