// extracted by mini-css-extract-plugin
export var container = "Home-module--container--LnkPY";
export var appear = "Home-module--appear--W+pQN";
export var shimmer = "Home-module--shimmer--RCtyd";
export var skyglow = "Home-module--skyglow--iRiPZ";
export var main = "Home-module--main--VJlwQ";
export var slide = "Home-module--slide--T6+tA";
export var footer = "Home-module--footer--KGBPf";
export var title = "Home-module--title--DZXOX";
export var description = "Home-module--description--grHxL";
export var flicker = "Home-module--flicker--NjsS5";
export var code = "Home-module--code--mIxer";
export var grid = "Home-module--grid--Wvrv5";
export var card = "Home-module--card--Juo7F";
export var logo = "Home-module--logo--dzazx";